
import { deleteList } from "~/helpers/apollo/apollo-list-query-helpers.js";

export default {
  props: {
    list: {
      type: Object,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteListLoading: false,
    };
  },

  methods: {
    deleteList: function () {
      this.deleteListLoading = true;

      this.$amplitude.track({ event_type: "Delete list" });

      deleteList.call(this, this.list.id).finally(() => {
        this.deleteListLoading = false;
        this.$emit("deleted");
      });
    },
  },
};
